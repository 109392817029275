<template>
  <div>
    <b-form @submit.prevent="submitForm" class="form-inputs--mobile--lg">
      <b-card>
        <template #header>
          <card-header
            title="Informazioni Personali"
            :button="{
              content: editButtonText,
              variant: 'info',
              classes: schedaSectionButtonClasses,
            }"
            :button-action="toggleEditMode"
          />
        </template>
        <b-form-row>
          <b-col md="3">
            <div :class="formImgClasses">
              <div class="form-img__img" :style="bgStyle"></div>
              <b-button
                class="form-img__btn"
                variant="link"
                @click="openModalImageUpload"
              >
                <font-awesome-icon
                  icon="plus"
                  class="fa-fw"
                />
              </b-button>
            </div>
          </b-col>
          <b-col class="mt-3 mt-md-0" md="9">
            <b-form-row>
              <b-col sm="6" lg="4">
                <field-text
                  :read-only="isDisabled"
                  fieldId="name"
                  placeholder="Nome"
                  title="Nome"
                  :state="validateState($v.formUserData.personInfo.name)"
                  v-model="$v.formUserData.personInfo.name.$model"
                />
              </b-col>
              <b-col sm="6" lg="4">
                <field-text
                  :read-only="isDisabled"
                  fieldId="surname"
                  placeholder="Cognome"
                  title="Cognome"
                  :state="validateState($v.formUserData.personInfo.surname)"
                  v-model="$v.formUserData.personInfo.surname.$model"
                />
              </b-col>
              <b-col sm="6" lg="4">
                <field-select
                  :read-only="isDisabled"
                  title="Sesso"
                  :options="genderOptions"
                  :state="validateState($v.formUserData.anagraficalInfo.gender)"
                  v-model="$v.formUserData.anagraficalInfo.gender.$model"
                ></field-select>
              </b-col>
              <b-col sm="6" lg="4">
                <field-text
                  :read-only="isDisabled"
                  fieldId="taxCode"
                  placeholder="Codice Fiscale"
                  title="Codice Fiscale"
                  :state="validateState($v.formUserData.anagraficalInfo.taxCode)"
                  v-model="$v.formUserData.anagraficalInfo.taxCode.$model"
                  :errorMessage="decodeError($v.formUserData, 'anagraficalInfo.taxCode')"
                />
              </b-col>
              <b-col sm="6" lg="4">
                <field-text
                  read-only
                  fieldId="email"
                  placeholder="Email"
                  title="Email"
                  :state="validateState($v.formUserData.personInfo.email)"
                  v-model="$v.formUserData.personInfo.email.$model"
                  :errorMessage="decodeError($v.formUserData, 'personInfo.email')"
                />
              </b-col>
              <b-col sm="6" lg="4">
                <field-text
                  :read-only="isDisabled"
                  fieldId="emailPEC"
                  placeholder="PEC"
                  title="Indirizzo PEC"
                  :state="validateState($v.formUserData.anagraficalInfo.mainPecEmail.contactData)"
                  v-model="$v.formUserData.anagraficalInfo.mainPecEmail.contactData.$model"
                  :errorMessage="decodeError($v.formUserData, 'anagraficalInfo.mainPecEmail.contactData')"
                />
              </b-col>
              <b-col sm="6" lg="4">
                <field-nations
                  :read-only="isDisabled"
                  title="Nazionalità"
                  :state="validateState($v.formUserData.anagraficalInfo.nationalityCountryCode)"
                  v-model="$v.formUserData.anagraficalInfo.nationalityCountryCode.$model"
                ></field-nations>
              </b-col>
              <b-col sm="6" lg="4">
                <field-calendar
                  :read-only="isDisabled"
                  title="Data di nascita"
                  :state="validateState($v.formUserData.anagraficalInfo.birthDate)"
                  v-model="$v.formUserData.anagraficalInfo.birthDate.$model"
                  :errorMessage="decodeError($v.formUserData, 'anagraficalInfo.birthDate')"
                ></field-calendar>
              </b-col>
              <b-col sm="6" lg="4">
                <field-text
                  :read-only="isDisabled"
                  fieldId="mobile"
                  placeholder="Cellulare"
                  title="Cellulare"
                  :state="validateState($v.formUserData.personInfo.mobilePhone)"
                  v-model="$v.formUserData.personInfo.mobilePhone.$model"
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="12">
                <field-city
                  :read-only="isDisabled"
                  title="Luogo di nascita"
                  field-id="birthCity"
                  v-model="$v.formUserData.anagraficalInfo.birthCity.$model"
                  :state="fieldStateBirthCity"
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="12">
                <field-address
                  :read-only="isDisabled"
                  title="Indirizzo di residenza"
                  field-id="residencialAddress"
                  v-model="$v.formUserData.residencialAddress.$model"
                  :state="fieldState"
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="12">
                <field-billing
                  :read-only="isDisabled"
                  title="Dati di fatturazione"
                  field-id="invoiceReference"
                  v-model="$v.formUserData.invoiceReference.$model"
                  :state="fieldStateInvoiceReference"
                />
              </b-col>
            </b-form-row>
            <div>
              <label class="col-form-label col-form-label--accent">Documenti</label>
              <div>
                <b-form-row>
                  <b-col cols="12">
                    <document-upload
                      :read-only="isDisabled"
                      :enableUpload="!readOnly"
                      :on-click="onUploadDoc"
                      :document="currentIdentityDoc"
                      entity-type="PERSON"
                      slim
                    />
                  </b-col>
                  <b-col md="6">
                    <field-text
                      :read-only="isDisabled"
                      fieldId="docNumber"
                      placeholder="Numero documento"
                      title="Numero documento"
                      :state="validateState($v.formUserData.docIdentity.docNumber)"
                      v-model="$v.formUserData.docIdentity.docNumber.$model"
                    />
                  </b-col>
                  <b-col md="6">
                    <field-calendar
                      :read-only="isDisabled"
                      title="Data di scadenza"
                      v-model="$v.formUserData.docIdentity.expireDate.$model"
                      :state="validateState($v.formUserData.docIdentity.expireDate, ['checkExpired'])"
                      :errorMessage="decodeError($v.formUserData, 'docIdentity.expireDate')"
                    ></field-calendar>
                  </b-col>
                </b-form-row>
              </div>
              <b-form-row>
                <b-col md="12" class="text-right">
                  <button-loader
                    v-if="!isDisabled"
                    button-style="success"
                    button-class="text-uppercase btn-success"
                    content="Salva"
                    :disabled="$v.formUserData.$invalid"
                    :loading="submitted"/>
                </b-col>
              </b-form-row>
            </div>
          </b-col>
        </b-form-row>
      </b-card>
      <b-card>
        <template #header>
          <card-header
            title="Termini di utilizzo e di iscrizione alla piattaforma"
          />
        </template>

        <div class="privacy-box">
          <div v-if="hasDataTerms" v-html="terms">
          </div>
        </div>
        <div class="privacy-list mt-3">
          <b-form-group
            class="custom-control custom-checkbox"
            v-for="privacy in privacyList"
            :key="privacy.key"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :checked="getPrivacyValue(privacy.key)"
              :mandatory="privacy.mandatory"
              :id="privacy.key"
              disabled
            >
            <label class="custom-control-label" :for="privacy.key">
              {{ privacy.value }}
            </label>
          </b-form-group>
        </div>
      </b-card>

    </b-form>
    <modal-document-upload
      :id="formDocUploadId"
      entity-type="PERSON"
      :entity-id="formUserData.personId"
      :document-name="documentToUpload.documentName"
      :document="documentToUpload.document"
      :on-success="updateDoc"
    />
    <modal-image-upload
      id="userImage"
      entity-type="PERSON"
      :entity-id="formUserData.personId"
      image-type="PROFILE"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import lodash from 'lodash';
import email from 'vuelidate/lib/validators/email';
import {
  checkAge, checkExpired,
  checkTaxCode, convertSetFieldName, decodeErrorMessage,
  decodeFormFieldError,
  fieldStateAddress,
  fieldStateCity,
  isNotEmpty, isPresent,
  omitNull, removeProp, serverError,
  VALIDATE_ADDRESS,
  VALIDATE_CITY,
  validateFormFieldState,
} from '@/utils/validators';
import { createPublicUri, genderTableList } from '@/utils/utilities';

const ButtonLoader = () => import('@/components/ButtonLoader.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');
const DocumentUpload = () => import('@/components/documents/DocumentUpload.vue');
const ModalDocumentUpload = () => import('@/components/documents/ModalDocumentUpload.vue');
const FieldNations = () => import('@/components/fields/FieldNations.vue');
const FieldCalendar = () => import('@/components/fields/FieldCalendar.vue');
const FieldText = () => import('@/components/fields/FieldText.vue');
const FieldSelect = () => import('@/components/fields/FieldSelect.vue');
const FieldAddress = () => import('@/components/fields/FieldAddress.vue');
const FieldCity = () => import('@/components/fields/FieldCity.vue');
const FieldBilling = () => import('@/components/fields/FieldBilling.vue');
const ModalImageUpload = () => import('@/components/userSections/ModalImageUpload.vue');

export default {
  name: 'FormUserData',
  components: {
    ModalImageUpload,
    DocumentUpload,
    ModalDocumentUpload,
    FieldCity,
    FieldAddress,
    FieldSelect,
    FieldText,
    ButtonLoader,
    FieldNations,
    FieldCalendar,
    FieldBilling,
    CardHeader,
  },
  props: {
    hasCompany: Boolean,
    changeCompanyTab: Function,
    readOnly: Boolean,
  },
  created() {
    this.populateForm();
    if (!this.$store.getters['tableUtils/hasTermsList'] && !this.$store.getters['tableUtils/isLoadingTerms']) {
      this.$store.dispatch('tableUtils/getTermsList').then(() => {
        console.log('loaded terms');
      });
    }
    if (!this.$store.getters['tableUtils/hasPrivacyList'] && !this.$store.getters['tableUtils/isLoadingPrivacy']) {
      this.$store.dispatch('tableUtils/getPrivacyList').then(() => {
        console.log('loaded Privacy');
      });
    }
  },
  data() {
    return {
      genderOptions: genderTableList,
      formUserData: null,
      submitted: false,
      showCompany: false,
      documentToUpload: {
        document: null,
        documentName: null,
      },
      editMode: false,
      clientValidation: {
        formUserData: {
          personInfo: {
            name: {
              required,
            },
            surname: {
              required,
            },
            mobilePhone: {
              required,
            },
            email: {
              email,
            },
          },
          anagraficalInfo: {
            gender: {
              required,
            },
            taxCode: {
              required,
              checkTaxCode,
            },
            birthCity: {
              ...VALIDATE_CITY,
            },
            birthDate: {
              required,
              checkAge,
            },
            nationalityCountryCode: {
              required,
            },
            mainPecEmail: {
              contactData: {
                email,
              },
            },
          },
          residencialAddress: {
            ...VALIDATE_ADDRESS,
          },
          invoiceReference: {},
          docIdentity: {
            docNumber: {
              required,
            },
            expireDate: {
              required,
              checkExpired,
            },
          },
        },
      },
      serverValidation: {},
    };
  },
  computed: {
    rules() {
      // When the serverValidation property is set this computed property
      // fires and merges the client and server validation
      return lodash.merge({}, this.serverValidation, this.clientValidation);
    },
    fieldState() {
      return fieldStateAddress(this.$v.formUserData.residencialAddress);
    },
    fieldStateInvoiceReference() {
      // return fieldStateAddress(this.$v.formUserData.billingInformation);
      return {};
    },
    fieldStateBirthCity() {
      return fieldStateCity(this.$v.formUserData.anagraficalInfo.birthCity);
    },
    currentIdentityDoc() {
      return this.$store.getters['subject/currentIdentityDoc'];
    },
    buttonText() {
      return this.showCompany ? 'Nascondi sezione società' : 'Inserisci i dati di una società';
    },
    isDisabled() {
      return (this.readOnly || !this.editMode);
    },
    // icon() {
    //   return this.editMode ? 'times' : 'pen';
    // },
    schedaSectionButtonClasses() {
      // if (this.editMode) return 'disabled';
      if (this.readOnly) return 'd-none';
      return '';
    },
    imgPath() {
      const imageProfile = this.$store.getters['subject/getImage']('PROFILE');
      return isNotEmpty(imageProfile) ? imageProfile.documentUpload.externalId : '';// set img here
    },
    formImgClasses() {
      const base = 'form-img';
      if (isPresent(this.imgPath)) return `${base} has-img`;
      return base;
    },
    bgStyle() {
      // img check, change condition as needed
      if (isPresent(this.imgPath)) {
        return `background-image: url(${createPublicUri(this.imgPath)});`;
      }
      return '';
    },
    editButtonText() {
      if (this.editMode) {
        return 'Annulla modifiche';
      }
      return 'Compila';
    },
    hasDataTerms() {
      return this.$store.getters['tableUtils/hasTermsList'];
    },
    terms() {
      const terms = this.$store.getters['tableUtils/getTermsList'];
      return terms.join(' ');
    },
    isLoadingTerms() {
      return this.$store.getters['tableUtils/isLoadingTerms'];
    },
    hasDataPrivacy() {
      return this.$store.getters['tableUtils/hasPrivacyList'];
    },
    privacyList() {
      return this.$store.getters['tableUtils/getPrivacyList'];
    },
    isLoadingPrivacy() {
      return this.$store.getters['tableUtils/isLoadingPrivacy'];
    },
    // extendedStatus() {
    //   return this.$store.getters['subject/currentExtendedStatus'];
    // },
    formDocUploadId() {
      return 'userFormDocs';
    },
  },
  validations() {
    return this.rules;
  },
  methods: {
    validateState: validateFormFieldState,
    decodeError: decodeFormFieldError,
    openModalUpload(doc, documentName) {
      this.documentToUpload = {
        document: doc,
        documentName,
      };
      this.$bvModal.show(this.formDocUploadId);
    },
    openModalImageUpload() {
      this.$bvModal.show('userImage');
    },
    toggleEditMode() {
      // console.log('this.editMode', this.editMode);
      this.editMode = !this.editMode;
      // console.log('this.editMode 2', this.editMode);
      if (!this.editMode) {
        this.$v.$reset();
        if (!this.submitted) {
          this.populateForm();
        }
      }
    },
    populateForm() {
      const person = this.$store.getters['subject/currentPerson'];
      const docIdentity = this.$store.getters['subject/currentIdentityDoc'];
      const deepcloned = lodash.cloneDeep(person);

      const empty = {
        personInfo: {
          name: null,
          surname: null,
          mobilePhone: null,
          email: null,
          emailPEC: {
            type: 'PEC',
            contactData: null,
          },
        },
        anagraficalInfo: {
          gender: null,
          taxCode: null,
          birthCity: {
            countryCode: null,
            city: null,
            province: null,
          },
          birthDate: null,
          nationalityCountryCode: null,
        },
        residencialAddress: {
          countryCode: null,
          streetName: null,
          zip: null,
          city: null,
          province: null,
        },
        invoiceReference: {
          taxCode: null,
          vatNumber: null,
          sdiCode: null,
          invoicePecEmail: {
            type: 'PEC',
            contactData: null,
          },
        },
        docIdentity: {
          docNumber: null,
          expireDate: null,
        },
      };

      if (isNotEmpty(deepcloned)) {
        this.formUserData = { ...empty, ...omitNull(deepcloned) };
        if (isNotEmpty(docIdentity) && isNotEmpty(docIdentity.additionalInfos)) {
          this.$set(this.formUserData.docIdentity, 'docNumber', docIdentity.additionalInfos.docNumber);
          this.$set(this.formUserData.docIdentity, 'expireDate', docIdentity.additionalInfos.expireDate);
          // this.formUserData.docIdentity.docNumber = docIdentity.additionalInfos.docNumber;
          // this.formUserData.docIdentity.expireDate = docIdentity.additionalInfos.expireDate;
        }
      } else {
        this.formUserData = { ...empty };
      }
      this.$v.$touch();
    },
    submitForm() {
      if (this.isDisabled) return;
      this.clearServerErrors();
      if (this.$v.$invalid) return;

      console.log('submitForm', this.formUserData);
      this.submitted = true;
      const { docIdentity, ...person } = this.formUserData;
      this.$store.dispatch('subject/saveSubject', {
        person,
      }).then(
        () => {
          const doc = this.$store.getters['subject/currentIdentityDoc'];
          const toUpdate = { ...doc, additionalInfos: { ...docIdentity } };
          this.$store.dispatch('documents/updateDocument', {
            document: toUpdate,
            entityClass: 'PERSON',
            entityId: person.personId,
          }).then((docUpdated) => {
            this.updateDoc(docUpdated).then(() => {
              this.toggleEditMode();
              this.submitted = false;
            },
            (responseError) => {
              this.processError(responseError.errors);
              this.submitted = false;
            });
          },
          (responseError) => {
            this.processError(responseError.errors);
            this.submitted = false;
          });
        },
        (responseError) => {
          this.processError(responseError.errors);
          this.submitted = false;
        },
      );
    },
    processError(errors) {
      if (!errors) {
        this.submitted = false;
        return;
      }
      console.log('errors', errors);
      const serverMessages = {
        serverErrors: {
        },
      };
      this.serverValidation = {
        formUserData: {
        },
      };

      errors.forEach((error) => {
        const { field, code } = error;
        if (isPresent(field)) {
          convertSetFieldName(serverMessages.serverErrors, field, decodeErrorMessage(field, code));
          convertSetFieldName(this.serverValidation.formUserData, field, {
            serverError: serverError(field, false, this.formUserData),
          });
        }
      });

      // Merge the server errors into the data. This doesn't yet cause
      // any validation errors but does make the messages (and therefore state)
      // accessible to the template.
      lodash.merge(this.formUserData, serverMessages);

      this.submitted = false;
    },
    clearServerErrors() {
      // Clearing the server validation removes the rules and
      // therefore server errors no longer contribute to validation state.
      this.serverValidation = {};
      // Clearing the server errors from the data isn't necessary either
      // but again makes for good housekeeping and we'd need to do this
      // if submitting this data to the server.
      removeProp(this.formData, 'serverErrors');
    },
    clearServerError(model, fieldName) {
      // When an input control is modified we see if there are any
      // server errors and clear them. This causes the serverError validation
      // rule to trigger and clear it's error state.
      // eslint-disable-next-line no-prototype-builtins
      if (model.hasOwnProperty('serverErrors')) {
        // eslint-disable-next-line no-prototype-builtins
        if (model.serverErrors.hasOwnProperty(fieldName)) {
          // eslint-disable-next-line no-param-reassign
          delete model.serverErrors[fieldName];
        }
      }
    },
    updateDoc(docUpdated) {
      return this.$store.dispatch('subject/updatePersonDocs', { identityDoc: docUpdated });
    },
    onUploadDoc() {
      this.openModalUpload(this.currentIdentityDoc, 'Documento d\'identità');
    },
    getPrivacyValue(privacyId) {
      const privacyList = this.$store.getters['subject/currentPrivacy'];
      const current = privacyList.filter((item) => item.privacyCode === privacyId);
      return current.length > 0 ? current[0].status : false;
    },
  },
};
</script>
